import * as Sentry from '@sentry/react';
import { useMutation } from '@tanstack/react-query';
// eslint-disable-next-line import/no-cycle
import endpoint from 'shared/api';

// eslint-disable-next-line import/no-cycle
import { CommonClaimAnswers, ZSubmitClaimInfo } from '../verticals/models';

export const useSubmitCommonClaim = () => {
  const onSubmitClaim = ({
    questionnaire,
    policyId,
  }: {
    questionnaire: Partial<CommonClaimAnswers>;
    policyId: string;
  }) => {
    const validation = ZSubmitClaimInfo.parse(questionnaire);

    const { claimType, payoutDetails, uploadDocuments, additionalInfo } =
      validation;

    const flattenedTokens = uploadDocuments.flatMap(({ token }) =>
      token !== undefined ? [token] : []
    );

    const submittableInfo = {
      userPolicyId: policyId,
      claimTypes: [
        {
          claimType,
          additionalInfo,
        },
      ],
      uploadTokens: flattenedTokens,
      amount: payoutDetails.amount,
      iban: payoutDetails.iban,
    };

    return endpoint.submitClaim(submittableInfo);
  };

  const mutation = useMutation({
    mutationFn: onSubmitClaim,
    onError: (error, variables) => {
      const { policyId } = variables;
      Sentry.captureException(error, {
        tags: {
          feature: 'CLAIMS',
          vertical: 'COMMON',
        },
        extra: {
          policyId,
          description: 'Error happened while submitting Common claims',
        },
      });
    },
  });

  return mutation;
};
