import { TFunction } from '@getpopsure/i18n-react';
import { Rule } from '@getpopsure/qnr-framework';
import type { ClaimQuestionnaire } from 'features/commonClaims/CommonClaims';

import { CommonClaimAnswers, PetHealthClaimType } from '../models';

export type PetHealthClaimAnswers = CommonClaimAnswers;

export const getTranslatedPetHealthQuestionnaire = (
  t: TFunction
): ClaimQuestionnaire => [
  {
    id: 'intro',
    type: 'INTRO',
    groupId: 'questionnaire',
    props: {},
    screen: {
      layout: 'Standalone',
      noMaxWidth: true,
    },
  },
  {
    id: 'claimType',
    required: true,
    type: 'RADIO',
    props: {
      mapValue: petHealthClaimTypeMapping(t),
    },
    screen: {
      question: t(
        'claims.petHealth.claimType.title',
        'What is this claim for?'
      ),
    },
    groupId: 'questionnaire',
  },
  {
    id: 'additionalInfo',
    required: true,
    type: 'TEXT',
    screen: {
      question: t(
        'claims.petHealth.additionalInfo.title',
        'Describe what happened'
      ),
      description: t(
        'claims.petHealth.additionalInfo.description',
        'Explain how things happened, including relevant dates and who was involved'
      ),
      skipDetails: {
        title: t(
          'claims.petHealth.additionalInfo.skipDetails.title',
          'Nothing to add'
        ),
        skipQuestion: { value: '' },
      },
    },
    props: {
      placeholder: t(
        'claims.petHealth.additionalInfo.placeholder',
        'E.g. my dog ate something in the park yesterday, leading to diarrhea. We went to the emergency vet service for treatment.'
      ),
    },
    groupId: 'questionnaire',
  },
  {
    id: 'uploadDocuments',
    type: 'UPLOAD',
    required: true,
    props: {
      showLegalNotice: true,
    },
    screen: {
      question: t('claims.petHealth.uploadDocuments.title', 'Upload documents'),
      description: t(
        'claims.petHealth.uploadDocuments.description',
        'Provide all documents relevant for this claim (e.g. invoices, prescriptions).'
      ),
    },
    groupId: 'questionnaire',
  },
  {
    id: 'payoutDetails',
    required: true,
    type: 'PAYOUT_DETAILS',
    groupId: 'questionnaire',
    props: {},
    screen: {
      buttonText: t('claims.petHealth.payoutDetails.button', 'Continue'),
    },
  },
  {
    id: 'processing',
    required: true,
    type: 'CLAIM_PROCESSING',
    props: {},
    screen: {
      layout: 'Standalone',
    },
    groupId: 'questionnaire',
  },
  {
    id: 'submitted',
    required: true,
    type: 'SUBMISSION',
    props: {},
    screen: {
      layout: 'Standalone',
    },
    groupId: 'questionnaire',
  },
];

export const getPetHealthRules = (): Rule<PetHealthClaimAnswers>[] => [];

export const petHealthClaimTypeMapping = (
  t: TFunction
): Partial<
  Record<PetHealthClaimType, string | { title: string; description: string }>
> => ({
  VETERINARY_VISITS: t(
    'claims.petHealth.claimType.veterinaryVisits.title',
    'Veterinary visits'
  ),
  SURGERY: t('claims.petHealth.claimType.surgery.title', 'Surgery'),
  MEDICATION: t('claims.petHealth.claimType.medication.title', 'Medication'),
  PREVENTATIVE_TREATMENT: t(
    'claims.petHealth.claimType.preventativeTreatment.title',
    'Preventative treatment'
  ),
  OTHER: t('claims.petHealth.claimType.other.title', 'Other'),
});
