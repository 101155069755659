import { UploadedFile } from 'shared/models/types';
import { ZUploadedDocumentsSchema } from 'shared/models/validations';
import type { SCREEN } from 'SignupQuestionnaire';
import { z } from 'zod';

const petHealthClaimTypes = [
  'SURGERY',
  'PREVENTATIVE_TREATMENT',
  'VETERINARY_VISITS',
  'MEDICATION',
  'OTHER',
] as const;

export type PetHealthClaimType = typeof petHealthClaimTypes[number];

export type PayoutDetails = { amount: number; iban: string };

export type CommonClaimAnswers = {
  intro: SCREEN;
  claimType: PetHealthClaimType;
  claimAmount: number;
  additionalInfo: string;
  uploadDocuments: UploadedFile[];
  payoutDetails: PayoutDetails;
  processing: boolean;
  submitted: boolean;
};

const ZPetHealthClaimAnswers = z.object({
  claimType: z.enum(petHealthClaimTypes),
  additionalInfo: z.string().optional(),
  uploadDocuments: z.array(ZUploadedDocumentsSchema),
  payoutDetails: z.object({ amount: z.number(), iban: z.string() }),
});

export const ZSubmitClaimInfo = ZPetHealthClaimAnswers;

export type CommonClaimSubmissionAnswers = z.infer<typeof ZSubmitClaimInfo>;
