import {
  QuestionnaireFormProps,
  QuestionnaireQuestions,
} from '@getpopsure/qnr-framework';
import { ErrorWithAction } from 'components/ErrorWithAction';
import routes from 'constants/routes';
import { useSubmitCommonClaim } from 'features/commonClaims/hooks/useSubmitCommonClaim';
import { useEffect, useRef, useState } from 'react';
import { generatePath, useHistory, useParams } from 'react-router';
import { useSafeTranslation } from 'shared/i18n';
import { ProcessingView as ActionProcessingView } from 'shared/pages/Processing/Processing.view';

type ProcessingProps<Questionnaire extends QuestionnaireQuestions> = {
  textList?: string[];
} & QuestionnaireFormProps<Questionnaire>;

export const ClaimProcessing = <Questionnaire extends QuestionnaireQuestions>({
  onSubmitValue,
  textList,
  description,
  questionnaireAnswers: questionnaire,
}: ProcessingProps<Questionnaire> & {
  description?: string;
}) => {
  const { t } = useSafeTranslation();
  const { policyId } = useParams<{ policyId: string }>();
  const { mutate: submitClaim, status, error } = useSubmitCommonClaim();
  const history = useHistory();

  const [processingFailed, setProcessingFailed] = useState(false);

  const refOnSubmit = useRef<(value: unknown) => void>(onSubmitValue);

  const TIMEOUT_TIME = 3_000;
  const elementIndex = 0;
  const fadeInAnimation = false;

  const list: string[] = textList
    ? [textList[0]]
    : [t('generic.qnr.processing.loadingText', 'Processing claim...')];

  useEffect(() => {
    const submit = () => {
      submitClaim({ questionnaire, policyId });
    };

    submit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (status === 'success') refOnSubmit.current(true);
      if (error) setProcessingFailed(true);
    }, TIMEOUT_TIME);

    return () => clearTimeout(timeoutId);
  }, [status, error]);

  const handlePolicyError = () => {
    const policyDetailsPage = generatePath(routes.claims.expat.path, {
      policyId,
    });
    history.push(policyDetailsPage);
  };

  if (processingFailed) {
    return (
      <ErrorWithAction
        title={t(
          'claims.common..submissionError.title',
          'Something went wrong'
        )}
        description={t(
          'claims.common..submissionError.description',
          'Sorry, something didn’t work as it should. Starting over should address this issue.'
        )}
        cta={{
          title: t('claims.common..submissionError.cta', 'Start over'),
          onClick: handlePolicyError,
        }}
      />
    );
  }

  return (
    <ActionProcessingView
      elementIndex={elementIndex}
      fadeInAnimation={fadeInAnimation}
      text={list}
      description={description}
    />
  );
};
