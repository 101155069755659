import {
  getRoutes,
  removeAnswers,
  RemoveAnswerType,
  stateManagerHelper,
} from '@getpopsure/qnr-framework';
import * as Sentry from '@sentry/react';
import { ErrorWithAction } from 'components/ErrorWithAction';
import LoadingSpinner from 'components/loadingSpinner';
import PageNotFound from 'components/pageNotFound';
import routes from 'constants/routes';
import { useGetPolicy } from 'features/policySingle/modules/useGetPolicy';
import { InsuranceTypes } from 'models/insurances/types';
import {
  generatePath,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router';
import { useSafeTranslation } from 'shared/i18n';
import {
  SignupQuestionnaire,
  SignupQuestionnaireType,
} from 'SignupQuestionnaire';

import { ClaimProcessing } from './components/ClaimProcessing/ClaimProcessing';
import { ClaimSubmitted } from './components/ClaimSubmitted/ClaimSubmitted';
import { GettingStarted } from './components/GettingStarted/GettingStarted';
import { PayoutDetails } from './components/PayoutDetails/PayoutDetails';
import { loadCommonClaimAnswers, storeCommonClaimAnswers } from './storage';
import {
  getTranslatedQuestionnaire,
  rules,
} from './verticals/getInsuranceTypeClaimConfig';
import { CommonClaimAnswers } from './verticals/models';

export type ClaimGroupIds = 'questionnaire';

export const CommonClaimComponents = {
  INTRO: GettingStarted,
  PAYOUT_DETAILS: PayoutDetails,
  SUBMISSION: ClaimSubmitted,
  CLAIM_PROCESSING: ClaimProcessing,
} as const;

export type ClaimQuestionnaire = SignupQuestionnaireType<
  CommonClaimAnswers,
  ClaimGroupIds,
  typeof CommonClaimComponents
>;

const removeProcessing: RemoveAnswerType<CommonClaimAnswers> = {
  op: 'always',
  questions: ['processing', 'submitted'],
};

const removeAllAnswers: RemoveAnswerType<CommonClaimAnswers> = {
  op: 'always',
  questions: [
    'claimType',
    'additionalInfo',
    'uploadDocuments',
    'payoutDetails',
    'processing',
    'submitted',
  ],
};

const removeAnswersLogic: Partial<
  Record<keyof CommonClaimAnswers, RemoveAnswerType<CommonClaimAnswers>>
> = {
  claimType: removeProcessing,
  uploadDocuments: removeProcessing,
  payoutDetails: removeProcessing,
  submitted: removeAllAnswers,
};

const INSURANCE_TYPE_WITH_COMMON_CLAIM_FLOW: InsuranceTypes[] = ['PET_HEALTH'];

export const CommonClaims = () => {
  const { policyId } = useParams<{
    policyId: string;
  }>();
  const { url } = useRouteMatch();

  const { t } = useSafeTranslation();

  const history = useHistory();

  const { isLoading: policyLoading, policy, error } = useGetPolicy(policyId);
  const policyNumber = policy?.attributes.policyNumber;

  const insuranceType = policy?.type as InsuranceTypes;

  const questionnaireAnswers = loadCommonClaimAnswers({
    insuranceType,
  });

  const handlePolicyError = () => {
    const policyDetailsPage = generatePath(routes.me.policies.path, {
      policyId,
    });
    history.push(policyDetailsPage);
  };

  if (policyLoading || !policy) {
    return <LoadingSpinner />;
  }

  if (!INSURANCE_TYPE_WITH_COMMON_CLAIM_FLOW.includes(insuranceType)) {
    return <PageNotFound />;
  }

  if (error || !policyNumber) {
    Sentry.captureException(
      `[Common claims] Failed to fetch policy. Claim process not initiated.`
    );
    return (
      <ErrorWithAction
        title={t('claims.common.startClaimError.title', 'Something went wrong')}
        description={t(
          'claims.common.startClaimError.description',
          'Sorry, something didn’t work as it should. Some information needed to submit the claim are missing.'
        )}
        cta={{
          title: t(
            'claims.common.startClaimError.cta',
            'Go back to your policies'
          ),
          onClick: handlePolicyError,
        }}
      />
    );
  }

  const onAnswer = <QuestionId extends keyof CommonClaimAnswers>(
    questionId: QuestionId,
    answer: CommonClaimAnswers[QuestionId]
  ) => {
    const answersToRemove = stateManagerHelper(
      removeAnswersLogic,
      questionnaire.components,
      questionnaireAnswers,
      questionnaire.rules
    ).getAnswersToRemove(questionId, answer) as (keyof CommonClaimAnswers)[];

    const nextState = removeAnswers(
      {
        ...questionnaireAnswers,
        [questionId]: answer,
      },
      answersToRemove
    );

    storeCommonClaimAnswers({
      answers: nextState,
      insuranceType,
    });
  };

  const questions = getTranslatedQuestionnaire({
    t,
    insuranceType,
  });

  const questionnaire = {
    components: questions,
    routes: getRoutes(questions, url),
    rules: rules(insuranceType),
  };

  return (
    <Switch>
      <Route path={routes.claims.common.questionnaire.path}>
        <SignupQuestionnaire
          questionnaireAnswers={questionnaireAnswers}
          questionnaire={questionnaire}
          onAnswer={onAnswer}
          configuration={{
            components: CommonClaimComponents,
          }}
          basePath={url}
          questionId="intro"
          featureName="CommonClaims"
        />
      </Route>
    </Switch>
  );
};
